import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/plugins/i18n';

import { isAuthenticated, hasRoles } from '@/middleware';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  // Base Routes
  {
    path: '/',
    name: 'Home',
    meta: { layout: true, module: 'home' },
    component: () => import(/* webpackChunkName: "Menu" */ '@/views/HomeView.vue'),
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next, router);
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
  },
  // Broiler Chicken Routes
  {
    path: '/broiler/performance',
    name: 'BroilerPerformance',
    meta: { layout: true, module: 'broiler' },
    component: () => import(/* webpackChunkName: "performance" */ '@/views/performance/PerformanceView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Performance');
    },
  },
  {
    path: '/broiler/performance/dashboard/:id',
    name: 'BroilerPerformanceDashboard',
    meta: { layout: true, module: 'broiler', dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "performanceDashboard" */ '@/views/performance/PerformanceDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Performance');
    },
  },
  {
    path: '/broiler/alerts/recent/',
    name: 'BroilerRecentAlerts',
    meta: { layout: true, module: 'broiler', props: true },
    component: () =>
      import(/* webpackChunkName: "alerts" */ '@/views/alert/RecentAlertsView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte');
    },
  },
  {
    path: '/broiler/alerts/history',
    name: 'BroilerAlertHistory',
    meta: { layout: true, module: 'broiler', props: true },
    component: () =>
      import(/* webpackChunkName: "alertsHistory" */ '@/views/alert/AlertHistoryView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte');
      next({ name: 'Home' });
    },
  },
  {
    path: '/broiler/alerts/recent/dashboard/:id',
    name: 'BroilerAlertDashboard',
    meta: { layout: true, module: 'broiler', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte');
    },
  },
  {
    path: '/broiler/alerts/history/dashboard/:id',
    name: 'BroilerAlertHistoryDashboard',
    meta: { layout: true, module: 'broiler', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte');
      next({ name: 'Home' });
    },
  },
  {
    path: '/broiler/slaughter-prediction/dashboard/:id',
    name: 'BroilerSlaughterPredictionDashboard',
    meta: { layout: true, module: 'broiler', dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "slaughterPredictionDashboard" */ '@/views/slaughter/SlaughterPredictionDashboard.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Predição');
    },
  },
  {
    path: '/broiler/pcp/dashboard/:id',
    name: 'BroilerPcpDashboard',
    meta: { layout: true, module: 'broiler', dashboard: true },
    component: () =>
      import(/* webpackChunkName: "PcpDashboardDashboard" */ '@/views/PcpDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Predição');
    },
  },
  {
    path: '/broiler/slaughter-prediction',
    name: 'BroilerSlaughterPrediction',
    meta: { layout: true, module: 'broiler' },
    component: () =>
      import(
        /* webpackChunkName: "slaughterPrediction" */ '@/views/slaughter/SlaughterPredictionView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Predição');
    },
  },
  {
    path: '/broiler/slaughter-prediction/planning',
    name: 'BroilerSlaughterPredictionPlanning',
    meta: { layout: true, module: 'broiler' },
    component: () =>
      import(
        /* webpackChunkName: "slaughterPredictionPlanning" */ '@/views/slaughter/SlaughterPredictionPlanningView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Predição');
    },
  },
  {
    path: '/broiler/sanity/dashboard/:id',
    name: 'BroilerSanityDashboard',
    meta: { layout: true, module: 'broiler', dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "sanityDashboard" */ '@/views/sanity/SanityDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Sanidade');
    },
  },
  {
    path: '/broiler/slaughter-prediction/planning/:id/:last_batch_id',
    name: 'BroilerSlaughterForecastDashboard',
    meta: { layout: true, module: 'broiler', dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "SlaughterForecastDashboard" */ '@/views/slaughter/SlaughterForecastDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Predição');
    },
  },
  {
    path: '/broiler/sanity',
    name: 'BroilerSanity',
    meta: { layout: true, module: 'broiler' },
    component: () =>
      import(/* webpackChunkName: "sanity" */ '@/views/sanity/SanityListView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Sanidade');
    },
  },
  {
    path: '/broiler/nutrition',
    name: 'BroilerNutrition',
    meta: { layout: true, module: 'broiler' },
    component: () => import(/* webpackChunkName: "nutrition" */ '@/views/nutrition/NutritionListView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
      hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Nutrição');
    },
  },
  {
    path: '/broiler/nutrition/dashboard/:id',
    name: 'BroilerNutritionDashboard',
    meta: { layout: true, module: 'broiler', dashboard: true },
    component: () => import(/* webpackChunkName: "nutritionDashboard" */ '@/views/nutrition/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      if (
        store.state.environment.selectedShed == null ||
        store.state.environment.selectedShed.silos != 0
      ) {
        return hasRoles(to, from, next, router, store, 'frangos_de_corte', 'Nutrição');
      }
      return next({ name: 'Home' });
    },
  },
  // Matrix Routes
  {
    path: '/matrix/performance/dashboard/:id/:sex',
    name: 'MatrixPerformanceDashboard',
    meta: { layout: true, module: 'matrix', props: true, dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "performanceDashboard" */ '@/views/performance/PerformanceDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro', 'Performance');
    },
  },
  {
    path: '/matrix/alerts/recent/',
    name: 'MatrixRecentAlerts',
    meta: { layout: true, module: 'matrix', props: true },
    component: () =>
      import(/* webpackChunkName: "alerts" */ '@/views/alert/RecentAlertsView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro');
    },
  },
  {
    path: '/matrix/alerts/history/',
    name: 'MatrixAlertHistory',
    meta: { layout: true, module: 'matrix', props: true },
    component: () =>
      import(/* webpackChunkName: "alertsHistory" */ '@/views/alert/AlertHistoryView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro');
      next({ name: 'Home' });
    },
  },
  {
    path: '/matrix/alerts/recent/dashboard/:id',
    name: 'MatrixAlertDashboard',
    meta: { layout: true, module: 'matrix', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro');
    },
  },
  {
    path: '/matrix/alerts/history/dashboard/:id',
    name: 'MatrixAlertHistoryDashboard',
    meta: { layout: true, module: 'matrix', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro');
      next({ name: 'Home' });
    },
  },
  {
    path: '/matrix/sanity/dashboard/:id',
    name: 'MatrixSanityDashboard',
    meta: { layout: true, module: 'matrix', dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "sanityDashboard" */ '@/views/sanity/SanityDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro', 'Sanidade');
    },
  },
  {
    path: '/matrix/nutrition/dashboard/:id',
    name: 'MatrixNutritionDashboard',
    meta: { layout: true, module: 'matrix', dashboard: true },
    component: () => import(/* webpackChunkName: "nutritionDashboard" */ '@/views/nutrition/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro', 'Nutrição');
    },
  },
  {
    path: '/matrix/performance',
    name: 'MatrixPerformance',
    meta: { layout: true, module: 'matrix' },
    component: () => import(/* webpackChunkName: "performance" */ '@/views/performance/PerformanceView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro', 'Performance');
    },
  },
  {
    path: '/matrix/sanity',
    name: 'MatrixSanity',
    meta: { layout: true, module: 'matrix' },
    component: () =>
      import(/* webpackChunkName: "sanity" */ '@/views/sanity/SanityListView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'matrizeiro', 'Sanidade');
    },
  },
  {
    path: '/matrix/nutrition',
    name: 'MatrixNutrition',
    meta: { layout: true, module: 'matrix' },
    component: () =>
      import(
        /* webpackChunkName: "nutrition" */ '@/views/nutrition/NutritionListView.vue'
      ),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
      hasRoles(to, from, next, router, store, 'matrizeiro', 'Nutrição');
    },
  },
  // Laying Birds Routes
  {
    path: '/laying-birds/sanity',
    name: 'LayingBirdsSanity',
    meta: { layout: true, module: 'laying-birds' },
    component: () =>
      import(/* webpackChunkName: "sanity" */ '@/views/sanity/SanityListView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'aves_poedeiras', 'Sanidade');
    },
  },
  {
    path: '/laying-birds/sanity/dashboard/:id',
    name: 'LayingBirdsSanityDashboard',
    meta: { layout: true, module: 'laying-birds', dashboard: true },
    component: () =>
      import(
        /* webpackChunkName: "sanityDashboard" */ '@/views/sanity/SanityDashboardView.vue'
      ),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'aves_poedeiras', 'Sanidade');
    },
  },
  {
    path: '/laying-birds/nutrition',
    name: 'LayingBirdsNutrition',
    meta: { layout: true, module: 'laying-birds' },
    component: () => import(/* webpackChunkName: "nutrition" */ '@/views/nutrition/NutritionListView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
      hasRoles(to, from, next, router, store, 'aves_poedeiras', 'Nutrição');
    },
  },
  {
    path: '/laying-birds/nutrition/dashboard/:id',
    name: 'LayingBirdsNutritionDashboard',
    meta: { layout: true, module: 'laying-birds', dashboard: true },
    component: () => import(/* webpackChunkName: "nutritionDashboard" */ '@/views/nutrition/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      if (
        store.state.environment.selectedShed == null ||
        store.state.environment.selectedShed.silos != 0
      ) {
        return hasRoles(to, from, next, router, store, 'aves_poedeiras', 'Nutrição');
      }
      return next({ name: 'Home' });
    },
  },
  {
    path: '/laying-birds/alerts/recent/dashboard/:id',
    name: 'LayingBirdsAlertDashboard',
    meta: { layout: true, module: 'laying-birds', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'aves_poedeiras');
    },
  },
  {
    path: '/laying-birds/alerts/history/dashboard/:id',
    name: 'LayingBirdsAlertHistoryDashboard',
    meta: { layout: true, module: 'laying-birds', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'aves_poedeiras');
      next({ name: 'Home' });
    },
  },
  {
    path: '/laying-birds/alerts/recent/',
    name: 'LayingBirdsRecentAlerts',
    meta: { layout: true, module: 'laying-birds', props: true },
    component: () =>
      import(/* webpackChunkName: "alerts" */ '@/views/alert/RecentAlertsView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'aves_poedeiras');
    },
  },
  {
    path: '/laying-birds/alerts/history',
    name: 'LayingBirdsAlertHistory',
    meta: { layout: true, module: 'laying-birds', props: true },
    component: () =>
      import(/* webpackChunkName: "alertsHistory" */ '@/views/alert/AlertHistoryView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'aves_poedeiras');
      next({ name: 'Home' });
    },
  },
  // {
  //   path: '/swine/performance',
  //   name: 'SwinePerformance',
  //   meta: { layout: true, module: 'swine' },
  //   component: () => import(/* webpackChunkName: "performance" */ '@/views/performance/PerformanceView.vue'),
  //   beforeEnter: (to, from, next) => {
  //     hasRoles(to, from, next, router, store, 'suinos', 'Performance');
  //   },
  // },
  // {
  //   path: '/swine/performance/dashboard/:id',
  //   name: 'SwinePerformanceDashboard',
  //   meta: { layout: true, module: 'swine', dashboard: true },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "performanceDashboard" */ '@/views/performance/PerformanceDashboardView.vue'
  //     ),
  //   beforeEnter: (to, from, next) => {
  //     hasRoles(to, from, next, router, store, 'suinos', 'Performance');
  //   },
  // },
  {
    path: '/swine/alerts/recent/',
    name: 'SwineRecentAlerts',
    meta: { layout: true, module: 'swine', props: true },
    component: () =>
      import(/* webpackChunkName: "alerts" */ '@/views/alert/RecentAlertsView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'suinos');
    },
  },
  {
    path: '/swine/alerts/history',
    name: 'SwineAlertHistory',
    meta: { layout: true, module: 'swine', props: true },
    component: () =>
      import(/* webpackChunkName: "alertsHistory" */ '@/views/alert/AlertHistoryView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'suinos');
      next({ name: 'Home' });
    },
  },
  {
    path: '/swine/alerts/recent/dashboard/:id',
    name: 'SwineAlertDashboard',
    meta: { layout: true, module: 'swine', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'suinos');
    },
  },
  {
    path: '/swine/alerts/history/dashboard/:id',
    name: 'SwineAlertHistoryDashboard',
    meta: { layout: true, module: 'swine', props: true, dashboard: true },
    component: () =>
      import(/* webpackChunkName: "alertsDashboard" */ '@/views/alert/AlertDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      hasRoles(to, from, next, router, store, 'suinos');
      next({ name: 'Home' });
    },
  },
  {
    path: '/swine/nutrition',
    name: 'SwineNutrition',
    meta: { layout: true, module: 'swine' },
    component: () => import(/* webpackChunkName: "nutrition" */ '@/views/nutrition/NutritionListView.vue'),
    beforeEnter: (to, from, next) => {
      store.state.nutrition.nutritionCardsIsLoaded = false;
      store.state.nutrition.nutritionIsLoaded = false;
      hasRoles(to, from, next, router, store, 'suinos', 'Nutrição');
    },
  },
  {
    path: '/swine/nutrition/dashboard/:id',
    name: 'SwineNutritionDashboard',
    meta: { layout: true, module: 'swine', dashboard: true },
    component: () => import(/* webpackChunkName: "nutritionDashboard" */ '@/views/nutrition/NutritionDashboardView.vue'),
    beforeEnter: (to, from, next) => {
      if (
        store.state.environment.selectedShed == null ||
        store.state.environment.selectedShed.silos != 0
      ) {
        return hasRoles(to, from, next, router, store, 'suinos', 'Nutrição');
      }
      return next({ name: 'Home' });
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  const pathParts = to.path.split('/').filter((part) => part !== '');

  store.state.environmentFilter.category = pathParts[0];

  const translatedParts = pathParts.map((part) => i18n.t(part, { default: part }));
  const translatedPath = translatedParts.join(' - ');

  document.title = translatedPath ? `Trinovati - ${translatedPath}` : 'Trinovati';
});

export default router;

import { api as axios } from '@/plugins/axios';

export const getRecentAlerts = async (token, limit, offset, params) => {
  const config = {
    method: 'get',
    url: '/v1/alerts/recent/',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      limit: limit,
      offset: offset,
      shed: params.shed,
      age: params.age,
      type: params.type,
      laying_birds: params.laying_birds,
      subtype: params.subtype,
      matrix: params.matrix ? params.matrix : null,
      ordering: params.ordering ?? null,
      production_type__external_id: params.production_type__external_id,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getAlertsHistory = async (token, limit, offset, params) => {
  const config = {
    method: 'get',
    url: '/v1/alerts/history/',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      limit: limit,
      offset: offset,
      shed: params.shed,
      age: params.age,
      type: params.type,
      subtype: params.subtype,
      matrix: params.matrix ? params.matrix : null,
      laying_birds: params.laying_birds,
      ordering: params.ordering ?? null
    },
  };
  const { data } = await axios(config);
  return data;
};

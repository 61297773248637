export default {
  'hello': 'Olá, {username}.',
  'comfort': 'Conforto',
  'comfortable': 'Confortável',
  'intermediary': 'Intermediário',
  'critical': 'Crítico',
  'no_data': 'Sem Dados',
  'lethal': 'Letal',
  'aviaries': 'Aviários',
  'aviary': 'Aviário',
  'loading_properties': 'Carregando propriedades',
  'none_found': 'Nenhuma propriedade foi encontrada',
  'empty_batch': 'Vazio Sanitário',
  'pre_lodged': 'Pré-alojamento',
  'lodged': 'Alojado',
  'slaughter': 'Abate',
  'storage': 'Armazenamento',
  'simulation': 'Simulação',
  'target_weight': 'Peso Alvo',
  'target_value': 'Ração Necessária',
  'priority': 'Prioritário',
  'entry_weight': 'Peso de Chegada',
  'weekly_weight_projection': 'Projeção de Peso por Idade',
  'leave_weight': 'Peso de Saída',
  'leave_age': 'Idade de Saída',
  'filtros': 'Filtros',
  'aviaries_page': 'Aviários por Página',
  'rations_page': 'Rações por Página',
  'stage': 'Fase',
  'conversionFactor': 'Fator de Conversão',
  'bed_temperature': 'Temperatura da Cama',
  'water_temperature': 'Temperatura da Água',
  'ambient-humidity': 'Umidade Ambiente',
  'ambient-temperature': 'Temperatura Ambiente',
  'humidity-ambient-temperature': 'Temperatura e Umidade Ambiente',
  'shed': 'Galpão',
  'ambience': 'Ambiência',
  'message': 'Mensagem',
  'alerts': 'Alertas',
  'creation_date': 'Data de Criação',
  'dateTime': 'Data e Hora',
  'qty24h': 'Últimas 24h',
  'type': 'Tipo',
  'subtype': 'Subtipo',
  'lineage': 'Linhagem',
  'sex': 'Sexo',
  'age': 'Idade',
  'real_age': 'Idade da Ave',
  'batch_age': 'Idade do Lote',
  'weight': 'Peso',
  'dwg': 'G.P.D',
  'alerts/recent': 'Alertas',
  'alerts/history': 'Histórico de Alertas',
  'recent': 'Recentes',
  'uniformity': 'Uniformidade',
  'performance': 'Desempenho',
  'producer': 'Produtor',
  'city': 'Cidade',
  'greater_than': 'Maior que',
  'less_than': 'Menor que',
  'alertTypeQty': 'Tipos',
  'totalQty': 'Quantidade',
  'quantityNf': 'Quantidade NF (kg)',
  'quantityTotalDeliveredNf': 'Ração total entregue NF (kg)',
  'total_delivered': 'Total entregue',
  'exactly': 'Exatamente',
  'and': 'E',
  'or': 'OU',
  'add': 'Adicionar',
  'remove': 'Remover',
  'identification': 'Identificação',
  'address': 'Endereço',
  'contact': 'Contato',
  'sub_region': 'Sub-região',
  'lodge': 'Alojamento',
  'current_lodge': 'Alojamento Atual',
  'date': 'Data',
  'effective_weight': 'Peso Efetivo',
  'nutrition': 'Nutrição',
  'sanity': 'Sanidade',
  'logout': 'Sair',
  'loading': 'Carregando',
  'en': 'Inglês',
  'es': 'Espanhol',
  'pt-br': 'Português',
  'slaughter_forecast': 'Projeção de Abate',
  'forecast': 'Projeção',
  'close': 'Fechar',
  'last_projected_weight': 'Último Peso Projetado',
  'actions': 'Ações',
  'predicted_weight': 'Peso Projetado',
  'select_a_date': 'Selecione uma Data',
  'days': 'dias',
  'production_days': 'dias em produção',
  'weeks': 'semanas',
  'production_weeks': 'semanas em produção',
  'production_time': 'Tempo em Produção',
  'prediction': 'Previsão',
  'predictions': 'Previsões',
  'fwu': 'P.P.A',
  'search': 'Buscar',
  'broiler': 'Frango de Corte',
  'broiler_chicken': 'Frango de Corte',
  'matrix': 'Matrizeiro',
  'swine': 'Suíno',
  'swines': 'Suínos',
  'home': 'Início',
  'status': 'Status',
  'silo': 'Silo',
  'ration': 'Ração',
  'rationType': 'Tipo de Ração',
  'capacity': 'Capacidade',
  'free': 'Livre',
  'available': 'Disponível',
  'occupation': 'Ocupação',
  'last_received_time': 'Última Leitura',
  'expected_end_date': 'Prévia de Término',
  'full': 'Cheio',
  'low': 'Baixo',
  'satisfactory': 'Satisfatório',
  'last_ration_delivered': 'Última Ração Entregue',
  'ration_delivered': 'Ração Entregue',
  'consumed': 'Consumido',
  'pre_initial': 'Pré-Inicial',
  'initial': 'Inicial',
  'growth': 'Crescimento',
  'general': 'Geral',
  'male': 'Macho',
  'female': 'Fêmea',
  'production_chicken': 'Aves de Produção',
  'hatch_chicken': 'Aves de Recria',
  'production': 'Produção',
  'hatch': 'Recria',
  'production_type': 'Tipo de Produção',
  'airsacculitis': 'Aerossaculite',
  'contaminated': 'Aves Condenadas',
  'total_delivered_ration': 'Total Entregue',
  'total_consumed': 'Consumo Total',
  'core': 'Núcleo',
  'pcp': 'Planejamento PCP',
  'pcp_filter': 'PCP',
  'dwg_filter': 'GPD',
  'weighing': 'Pesagem',
  'StorageCapacity': 'Capacidade de armazenamento',
  'TotalFeedInTheField': 'Total de ração em campo',
  'TotalFree': 'Total livre',
  'nutritionTotals': 'Totais',
  'total_free': 'Total Disponível (kg)',
  'siloRead': 'Leitura Silo (kg)',
  'addRationDelivery': 'Cadastrar entrega de ração',
  'last_read': 'Última Leitura',
  'period': 'Período',
  'from': 'De',
  'until': 'Até',
  'predictedEndOfFeed': 'Previsão de Fim de Ração',
  'update': 'Atualizar',
  'groupBy': 'Agrupar por',
  'last_days': 'Últimos dias',
  'between': 'Datas',
  'silo_1': 'Silo 1',
  'silo_2': 'Silo 2',
  'average_consumption': 'Consumo Médio',
  'average_consumption_per_animal': 'Consumo Médio Por Indivíduo',
  'total': 'Total',
  'occupation_history': 'Consumo de Ração',
  'occupation_percent': 'Ocupação (%)',
  'week': 'Semana',
  'age_range': 'Faixa de idade',
  'cancel': 'Cancelar',
  'phase': 'Fase',
  'density': 'Densidade',
  'semi_annual_planning': 'Planejamento Semanal',
  'generateReport': 'Gerar relatório',
  'target_date': 'Data/Hora Alvo',
  'target_age': 'Idade Alvo',
  'need_for_feed': 'Ração necessária',
  'accumulated_consumption': 'Consumo acumulado',
  'weight_prediction': 'Predição de peso',
  'target_weight_prediction': 'Peso Predito',
  'target_weight_company': 'Peso Alvo Companhia',
  'laying-birds': 'Aves Poedeiras',
  'alerts-history': 'Histórico de Alertas',
  'production_birds': 'Aves de Produção',
  'breeding_birds': 'Aves de Recria',
  'Comfortable': 'Confortável',
  'Intermediary': 'Intermediário',
  'Critical': 'Crítico',
  'Lethal': 'Letal',
  'login': 'Entrar',
  'login_title': 'Acesse a sua conta.',
  'username': 'Usuário',
  'username_placeholder': 'Digite seu usuário',
  'password': 'Senha',
  'password_placeholder': 'Digite sua senha',
  'comfort_map': 'Mapa de conforto',
  'amplitude': 'Amplitude',
  'last_reading': 'Última leitura',
  'percent': 'Percentual',
  'property': 'Propriedade',
};

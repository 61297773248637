class Enthalpy {
  constructor(data = null) {
    if (data !== null) {
      this.code = data.code;
      this.description = data.description;
      this.deviceId = data.device_id;
      this.position = data.position;
      this.timestamp = data.timestamp;
    }
  }
}

class Silo {
  constructor(data = null) {
    if (data !== null) {
      this.id = data.id;
      this.availableWeight = data.available_weight;
      this.freeWeight = data.free_weight;
      this.status = data.status;  
      this.lastReceivedTime = data.last_received_time;  
    }
  }
}

class PcPProjection {
  constructor(data = null) {
    if (data !== null) {
      this.age = data.age;
      this.weight = data.weight;
      this.projection = data.projection;
    }
  }
}

class Sex {
  constructor(data = null) {
    if (data !== null) {
      this.name = data.name;
      this.externalId = data.external_id;
    }
  }
}

export class Batch {
  constructor(data = null) {
    if (data !== null) {
      this.id = data.id;
      this.lineage = data.lineage;
      this.sex = new Sex(data.sex);
      this.age = data.age;
      this.realAge = data.real_age;
      this.productionType = data.production_type;
      
      // Performance
      this.weight = data.weight;
      this.gain = data.gain;
      this.performance = data.performance;
      this.uniformity = data.variation_coef;
      this.conversionFactor = data.conversion_factor;
      
      // Nutrition
      this.silos = Array.isArray(data.silos) ? data.silos.map(silo => new Silo(silo)) : [];
      
      // Sanity
      this.enthalpies = Array.isArray(data.enthalpy) ? data.enthalpy.map(e => new Enthalpy(e)) : [];
      this.airsacculitis = data.airsacculitis;
      this.contaminated_birds = data.contaminated_birds;

      // Slaughter
      this.entryWeight = data.entry_weight;
      this.pcpProjections = Array.isArray(data.pcp_projections) ? data.pcp_projections.map(pcp => new PcPProjection(pcp)) : [];
    }
  }
}

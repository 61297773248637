export default {
  'hello': 'Hola, {username}.',
  'comfort': 'Comodidad',
  'comfortable': 'Cómodo',
  'intermediary': 'Intermediario',
  'critical': 'Crítico',
  'no_data': 'Sin datos',
  'lethal': 'Letal',
  'aviaries': 'Aviarios',
  'aviary': 'Aviario',
  'loading_properties': 'Cargando propiedades',
  'none_found': 'No se encontraron propiedades',
  'empty_batch': 'Lote vacío',
  'pre_lodged': 'Pre-alojado',
  'lodged': 'Alojado',
  'slaughter': 'Sacrificio',
  'storage': 'Almacenamiento',
  'simulation': 'Simulación',
  'target_weight': 'Peso objetivo',
  'target_value': 'Ración necesaria',
  'priority': 'Prioridad',
  'entry_weight': 'Peso de entrada',
  'weekly_weight_projection': 'Proyección de peso por edad',
  'leave_weight': 'Peso de salida',
  'leave_age': 'Edad de salida',
  'filtros': 'Filtros',
  'aviaries_page': 'Aviarios por página',
  'rations_page': 'Raciones por página',
  'stage': 'Etapa',
  'conversionFactor': 'Factor de conversión',
  'bed_temperature': 'Temperatura de la cama',
  'water_temperature': 'Temperatura del agua',
  'ambient-humidity': 'Humedad ambiental',
  'ambient-temperature': 'Temperatura ambiental',
  'humidity-ambient-temperature': 'Humedad y temperatura ambiental',
  'shed': 'Galpón',
  'ambience': 'Ambiente',
  'message': 'Mensaje',
  'alerts': 'Alertas',
  'creation_date': 'Fecha de creación',
  'dateTime': 'Fecha y hora',
  'qty24h': 'Últimas 24h',
  'type': 'Tipo',
  'subtype': 'Subtipo',
  'lineage': 'Linaje',
  'sex': 'Sexo',
  'age': 'Edad',
  'real_age': 'Edad real del ave',
  'batch_age': 'Edad del lote',
  'weight': 'Peso',
  'dwg': 'GPD',
  'alerts/recent': 'Alertas recientes',
  'alerts/history': 'Historial de alertas',
  'recent': 'Recientes',
  'uniformity': 'Uniformidad',
  'performance': 'Desempeño',
  'producer': 'Productor',
  'city': 'Ciudad',
  'greater_than': 'Mayor que',
  'less_than': 'Menor que',
  'alertTypeQty': 'Tipos',
  'totalQty': 'Cantidad',
  'quantityNf': 'Cantidad NF (kg)',
  'quantityTotalDeliveredNf': 'Ración total entregada NF (kg)',
  'total_delivered': 'Total entregado',
  'exactly': 'Exactamente',
  'and': 'Y',
  'or': 'O',
  'add': 'Añadir',
  'remove': 'Eliminar',
  'identification': 'Identificación',
  'address': 'Dirección',
  'contact': 'Contacto',
  'sub_region': 'Subregión',
  'lodge': 'Alojar',
  'current_lodge': 'Alojamiento actual',
  'date': 'Fecha',
  'effective_weight': 'Peso efectivo',
  'nutrition': 'Nutrición',
  'sanity': 'Sanidad',
  'logout': 'Cerrar sesión',
  'loading': 'Cargando',
  'en': 'Inglés',
  'es': 'Español',
  'pt-br': 'Portugués',
  'slaughter_forecast': 'Pronóstico de sacrificio',
  'forecast': 'Proyección',
  'close': 'Cerrar',
  'last_projected_weight': 'Último peso proyectado',
  'actions': 'Acciones',
  'predicted_weight': 'Peso previsto',
  'select_a_date': 'Seleccione una fecha',
  'days': 'Días',
  'production_days': 'Días de producción',
  'weeks': 'Semanas',
  'production_weeks': 'Semanas de producción',
  'production_time': 'Tiempo de producción',
  'prediction': 'Predicción',
  'predictions': 'Predicciones',
  'fwu': 'Uso efectivo del alimento',
  'search': 'Buscar',
  'broiler': 'Pollo de engorde',
  'broiler_chicken': 'Pollo de engorde',
  'matrix': 'Matriz',
  'swine': 'Cerdo',
  'swines': 'Cerdos',
  'home': 'Inicio',
  'status': 'Estado',
  'silo': 'Silo',
  'ration': 'Ración',
  'rationType': 'Tipo de ración',
  'capacity': 'Capacidad',
  'free': 'Libre',
  'available': 'Disponible',
  'last_received_time': 'Última Lectura',
  'occupation': 'Ocupación',
  'expected_end_date': 'Fecha de finalización esperada',
  'full': 'Lleno',
  'low': 'Bajo',
  'satisfactory': 'Satisfactorio',
  'last_ration_delivered': 'Última ración entregada',
  'ration_delivered': 'Ración entregada',
  'consumed': 'Consumido',
  'pre_initial': 'Pre-inicial',
  'initial': 'Inicial',
  'growth': 'Crecimiento',
  'general': 'General',
  'male': 'Macho',
  'female': 'Hembra',
  'production_chicken': 'Aves de producción',
  'hatch_chicken': 'Aves de recría',
  'production': 'Producción',
  'hatch': 'Incubación',
  'production_type': 'Tipo de producción',
  'airsacculitis': 'Aerosaculitis',
  'contaminated': 'Aves condenadas',
  'total_delivered_ration': 'Total entregado',
  'total_consumed': 'Total consumido',
  'core': 'Núcleo',
  'pcp': 'Planificación PCP',
  'pcp_filter': 'PCP',
  'dwg_filter': 'GPD',
  'weighing': 'Pesaje',
  'StorageCapacity': 'Capacidad de almacenamiento',
  'TotalFeedInTheField': 'Alimento total en el campo',
  'TotalFree': 'Total disponible',
  'nutritionTotals': 'Totales',
  'total_free': 'Total disponible (kg)',
  'siloRead': 'Lectura de silo (kg)',
  'addRationDelivery': 'Registrar entrega de ración',
  'last_read': 'Última lectura',
  'period': 'Período',
  'from': 'De',
  'until': 'Hasta',
  'predictedEndOfFeed': 'Fin previsto del alimento',
  'update': 'Actualizar',
  'groupBy': 'Agrupar por',
  'last_days': 'Últimos días',
  'between': 'Entre',
  'silo_1': 'Silo 1',
  'silo_2': 'Silo 2',
  'average_consumption': 'Consumo promedio',
  'average_consumption_per_annimal': 'Consumo promedio por individuo',
  'total': 'Total',
  'occupation_history': 'Historial de ocupación',
  'occupation_percent': 'Ocupación (%)',
  'week': 'Semana',
  'age_range': 'Rango de edad',
  'cancel': 'Cancelar',
  'phase': 'Fase',
  'density': 'Densidad',
  'semi_annual_planning': 'Planificación semestral',
  'generateReport': 'Generar informe',
  'target_date': 'Fecha/Hora objetivo',
  'target_age': 'Edad objetivo',
  'need_for_feed': 'Ración necesaria',
  'accumulated_consumption': 'Consumo acumulado',
  'weight_prediction': 'Predicción de peso',
  'target_weight_prediction': 'Peso previsto',
  'target_weight_company': 'Peso objetivo de la compañía',
  'laying-birds': 'Aves ponedoras',
  'alerts-history': 'Historial de alertas',
  'production_birds': 'Aves de producción',
  'breeding_birds': 'Aves de cría',
  'Comfortable': 'Cómodo',
  'Intermediary': 'Intermediario',
  'Critical': 'Crítico',
  'Lethal': 'Letal',
  'login': 'Iniciar sesión',
  'login_title': 'Inicie sesión en su cuenta.',
  'username': 'Usuario',
  'username_placeholder': 'Ingrese su usuario',
  'password': 'Contraseña',
  'password_placeholder': 'Ingrese su contraseña',
  'comfort_map': 'Mapa de comodidad',
  'amplitude': 'Amplitud',
  'last_reading': 'Última lectura',
  'percent': 'Porcentual',
  'property': 'Propiedad',
};

import { Batch } from './Batch.js';

export class Shed {
  constructor(data = null) {
    if (data !== null) {
      this.id = data.id;
      this.name = data.name;
      this.property = data.property;
      this.lastBatch = new Batch(data.last_batch);

      // Slaughter
      this.targetWeight = data.target_value;
    }
  }
}

import { api as axios } from '@/plugins/axios.js';

export const getShedsLegacy = async (
  token,
  limit = null,
  offset = null,
  params = null,
  state = null
) => {
  const config = {
    method: 'get',
    url: '/v1/sheds/list',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      platform: true,
      limit: limit,
      offset: offset,
      state: state,
      stage: params.stage,
      enthalpy: params.enthalpy,
      lineage: params.lineage,
      sex: params.sex,
      producer: params.producer,
      age: params.age,
      performance: params.performance,
      variation_coef: params.variation_coef,
      weight: params.weight,
      priority: params.priority ? params.priority : null,
      predicted_weight: params.predicted_weight,
      predicted_weight_date: params.predicted_weight_date,
      ordering: params.ordering ?? null,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getSheds = async (
  token,
  limit = null,
  offset = null,
  params = null,
  state = null
) => {
  const config = {
    method: 'get',
    url: `/v2/sheds/${state}`,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: {
      // Pagination
      limit,
      offset,

      // Filters by attributes
      name: params.name,
      property: params.property,
      lineage: params.lineage,
      sex: params.sex,
      age: params.age,
      production_type__external_id: params.production_type__external_id,

      // Filters by performance
      weight: params.weight,
      variation_coef: params.variation_coef,
      performance: params.performance,

      // Filters by nutrition

      // Filters by Status
      enthalpy: params.enthalpy,
      stage: params.stage,
      priority: params.priority,

      // Sorting
      ordering: params.ordering,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getShedDetails = async (token, id, params = undefined) => {
  const config = {
    method: 'get',
    url: `/v1/silos-detail/${id}`,
    params,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getShedDetailsOld = async (token, id) => {
  const config = {
    method: 'get',
    url: `/v1/sheds/${id}/detail`,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getShedDivisionDetail = async (token, pathParams, params = undefined) => {
  const id = pathParams.id;
  const shed_division_id = pathParams.shed_division_id;
  const url = ['/v1', 'sheds', id, 'shed_division', shed_division_id, 'detail']
    .filter(Boolean)
    .join('/');
  const config = {
    method: 'get',
    url: url,
    params,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getShedDivisionEnthalpy = async (token, pathParams, params = undefined) => {
  const id = pathParams.id;
  const shed_division_id = pathParams.shed_division_id;
  const url = ['/v1', 'sheds', id, 'shed_division', shed_division_id, 'enthalpy']
    .filter(Boolean)
    .join('/');
  const config = {
    method: 'get',
    url,
    params,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getShedBatches = async (token, shedId) => {
  const config = {
    method: 'get',
    url: '/v1/batches',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      'shed': shedId,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getShedBatchesNutrition = async (token, shedId) => {
  const config = {
    method: 'get',
    url: '/v1/batches-shed/' + shedId,
    headers: {
      'Authorization': 'Bearer ' + token,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getDevices = async (token, shedId, offset) => {
  const config = {
    method: 'get',
    url: '/v1/devices',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      'shed_id': shedId,
      'offset': offset,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getBoxDevices = async (token, offset) => {
  const config = {
    method: 'get',
    url: '/v1/device-box',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      'offset': offset,
    },
  };
  const { data } = await axios(config);
  return data;
};

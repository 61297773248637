import { isArray, isNotNull } from '@/utils';

export const actions = {
  resolveParams({ getters, commit }, state = null) {
    let requestParams = getters.getRequestParams;
    let params = getters.getParams;
    let filters = [];

    params.production_type__external_id = {
      'matrix': "1,2",
      'broiler': "3",
      'laying-birds': "6,7",
      'swine': "5",
    }[getters.getCategory];

    if (state === 'pcp') {
      filters = getters.getPcpConfiguredFilters || [];
    } else {
      filters = getters.getConfiguredFilters || [];
    }

    for (const key in requestParams) {
      if (Array.isArray((requestParams[key] = []))) {
        requestParams[key] = [];
      } else {
        requestParams[key] = '';
      }
    }

    for (const key in filters) {
      let field = filters[key].field;
      let value = filters[key].value;

      if (!isArray(field)) {
        if (!requestParams[field].includes(value)) {
          if (filters[key].operator === 'isExactly') {
            requestParams[field].push('eq_' + value);
          } else if (filters[key].operator === 'isSmaller') {
            requestParams[field].push('lt_' + value);
          } else if (filters[key].operator === 'isBigger') {
            requestParams[field].push('gt_' + value);
          } else {
            requestParams[field].push(value);
          }
        }
      } else {
        for (let i = 0; i < field.length; i++) {
          if (!requestParams[field[i]].includes(value[i])) {
            if (filters[key].operator[i] === 'isExactly' && field[i] != 'predicted_weight_date') {
              requestParams[field[i]].push('eq_' + value[i]);
            } else if (filters[key].operator[i] === 'isSmaller') {
              requestParams[field[i]].push('lt_' + value[i]);
            } else if (filters[key].operator[i] === 'isBigger') {
              requestParams[field[i]].push('gt_' + value[i]);
            } else {
              requestParams[field[i]].push(value[i]);
            }
          }
        }
      }
    }

    if (['pcp', 'performance'].includes(state)) {
      let stageParams = {
        'empty_batch': getters.getShowDisabledProperties,
        'slaughter': getters.getShowSlaughterState,
        'lodged': getters.getShowLodgeState,
        'pre_lodged': getters.getShowPreLodgeState,
      };

      if (stageParams.lodged === true) {
        stageParams.slaughter = true;
      }

      for (const key in stageParams) {
        if (stageParams[key] === true) {
          requestParams.stage.push(key);
        }
      }
    }

    for (const key in requestParams) {
      if (Array.isArray(requestParams[key]) && requestParams[key].length > 0) {
        requestParams[key] = requestParams[key].join(',');
      }
    }

    requestParams['priority'] = getters.getShowPriorityState;
    commit('SET_REQUEST_PARAMS', { ...(requestParams || {}), ...(params || {}) });
  },

  toggleParam({ commit, getters }, newParam) {
    console.log('newParam', newParam);
    let params = getters.getParams;
    commit('SET_PARAMS', { ...params, ...newParam });
  },

  removeFilter({ commit, getters }, indexOfArray) {
    if (indexOfArray.state === 'pcp') {
      let filters = getters.getPcpConfiguredFilters || [];
      if (isNotNull(indexOfArray)) {
        filters.splice(indexOfArray, 1);
      }
      commit('SET_PCP_CONFIGURED_FILTERS', filters);
    } else {
      let filters = getters.getConfiguredFilters || [];
      if (isNotNull(indexOfArray)) {
        filters.splice(indexOfArray, 1);
      }
      commit('SET_CONFIGURED_FILTERS', filters);
    }
  },

  addFilter({ commit, getters }, filter) {
    if (filter.state === 'pcp') {
      let index = getters.getPcpIndexFilter;
      let filters = getters.getPcpConfiguredFilters || [];
      filters.push({ index, ...filter });
      index++;
      commit('SET_PCP_CONFIGURED_FILTERS', filters);
      commit('SET_PCP_INDEX_FILTER', index);
    } else {
      let index = getters.getIndexFilter;
      let filters = getters.getConfiguredFilters || [];
      filters.push({ index, ...filter });
      index++;
      commit('SET_CONFIGURED_FILTERS', filters);
      commit('SET_INDEX_FILTER', index);
    }
  },
};
